const toggleMenuButton = document.querySelector(".toggleMenuButton");
const toggleMenuIconClose = toggleMenuButton.querySelector(".toggleMenuIconClose");
const toggleMenuIconDefault = toggleMenuButton.querySelector(".toggleMenuIconDefault");
const mainNavigation = document.querySelector("#mainNavigation");
// console.log('toggleMenuButton', toggleMenuButton);

function toggleMenu(event) {
	event.preventDefault();
	document.body.classList.toggle('BodyNavigationOpened');
	mainNavigation.classList.toggle('NavigationOpened');
	toggleMenuIconClose.classList.toggle('hidden');
	toggleMenuIconDefault.classList.toggle('hidden');
}

export function init() {
	toggleMenuButton.addEventListener("click", toggleMenu, false);
}
