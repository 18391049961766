import Splide from "@splidejs/splide";
import { init as initMenuMobile } from "./menu-mobile";

initMenuMobile();

document.addEventListener( 'DOMContentLoaded', function() {

	if ( document.querySelector('.splideHero') ) {
		var splideHero = new Splide( '.splideHero' , {
			type : 'loop',
			autoplay: true,
			interval: 3500,
		});
		splideHero.mount();
	}
	
	if ( document.querySelector('.splideCarousel') ) {
		var splideCarousel = new Splide( '.splideCarousel' , {
			type : 'loop',
			autoplay: true,
			interval: 3500,
			perPage: 3,
			breakpoints: {
				992: {
					perPage: 2,
				},
				720: {
					perPage: 1,
				},
			}
		});
		splideCarousel.mount();
	}

	if ( document.querySelector('.splideBrands') ) {
		var splideBrands = new Splide( '.splideBrands', {
			type : 'loop',
			perPage: 5,
			arrows: false,
			autoplay: true,
			pagination: false,
			interval: 1500,
		} );
		splideBrands.mount();
	}

} );

document.addEventListener('DOMContentLoaded', () => {
	
	function toggleModal(modalID) {
		document.getElementById(modalID).classList.toggle("hidden");
	}
	
	function closeModal(modalID) {
		document.getElementById(modalID).classList.add("hidden");
	}
	
	document.querySelectorAll('[data-modal-toggle]').forEach(el => {
		const modalId = el.getAttribute('data-modal-toggle');
		el.addEventListener('click', function(e) {
			e.preventDefault();
			toggleModal(modalId);
		})
	});
	
	document.querySelectorAll('[data-modal-close]').forEach(el => {
		const modalId = el.getAttribute('data-modal-close');
		el.addEventListener('click', function(e) {
			e.preventDefault();
			closeModal(modalId);
		})
	});
});


// Tabs
const tabButtons = document.querySelectorAll("[data-tabs]");

const onTabButtonClick = (btn, siblings, target) => {
	const tabTarget = btn.dataset.tabTarget;
	const tabContentSiblings = target.querySelectorAll(`[data-tab-content]`);
	const tabContentTarget = target.querySelector(`[data-tab-content="${tabTarget}"]`);

	btn.addEventListener("click", (e) => {
		e.preventDefault();
		
		siblings.forEach(obj => obj.classList.remove("TabButtonActive"));
		btn.classList.add('TabButtonActive');
		
		tabContentSiblings.forEach( item => item.classList.add('hidden'));
		tabContentTarget.classList.remove('hidden');
	});
}

const addEventToItem = (items, target) => {
	items.forEach( item => onTabButtonClick(item, items, target) );
}

tabButtons.forEach((tabs, index) => {
	const tabsItems = tabs.querySelectorAll("[data-tab-button]");
	const target = tabs.dataset.tabs;
	const targetEl = document.getElementById(target);
	addEventToItem(tabsItems, targetEl);
});




